<template>
  <InnerScroll
    :title="
      sent
        ? $t('system.feedback.submit.successHead')
        : $t('system.navigation.feedback')
    "
    :loading="loading"
  >
    <FeedbackForm
      :loading="loading"
      :entry-text="entryText"
      :form-fields="formFields"
      :sent.sync="sent"
    />
  </InnerScroll>
</template>

<script>
import api from 'api'
import FeedbackForm from 'organisms/Forms/Feedback/Feedback'
import InnerScroll from '../InnerScroll/InnerScroll.vue'

export default {
  components: {
    FeedbackForm,
    InnerScroll
  },

  data: () => ({
    loading: false,
    entryText: '',
    formFields: [],
    sent: false
  }),

  created() {
    this.getFormData()
  },

  methods: {
    async getFormData() {
      this.loading = true

      try {
        const response = await api.call('getFeedbackData')
        this.entryText = response.data.results.entryText
        this.formFields = response.data.results.items
      } catch (error) {
        this.$error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .e-loading {
  display: block;
  margin: 0 auto;
}
</style>
