<template>
  <div class="e-feedback__item">
    <div class="e-feedback__label" v-text="label" />

    <button
      :class="{
        'e-feedback__button--active': activeIcon === 1
      }"
      class="e-feedback__button e-feedback__button--danger"
      type="button"
      @click="setChoice(1)"
    >
      <span @mouseenter="hoverIcon = 1" @mouseleave="hoverIcon = null">
        <IconBad />
      </span>
    </button>

    <button
      :class="{
        'e-feedback__button--active': activeIcon === 2
      }"
      class="e-feedback__button e-feedback__button--semi-danger"
      type="button"
      @click="setChoice(2)"
    >
      <span @mouseenter="hoverIcon = 2" @mouseleave="hoverIcon = null" />
    </button>

    <button
      :class="{
        'e-feedback__button--active': activeIcon === 3
      }"
      class="e-feedback__button e-feedback__button--neutral"
      type="button"
      @click="setChoice(3)"
    >
      <span @mouseenter="hoverIcon = 3" @mouseleave="hoverIcon = null">
        <IconNeutral />
      </span>
    </button>

    <button
      :class="{
        'e-feedback__button--active': activeIcon === 4
      }"
      class="e-feedback__button e-feedback__button--semi-success"
      type="button"
      @click="setChoice(4)"
    >
      <span @mouseenter="hoverIcon = 4" @mouseleave="hoverIcon = null" />
    </button>

    <button
      :class="{
        'e-feedback__button--active': activeIcon === 5
      }"
      class="e-feedback__button e-feedback__button--success"
      type="button"
      @click="setChoice(5)"
    >
      <span @mouseenter="hoverIcon = 5" @mouseleave="hoverIcon = null">
        <IconGood />
      </span>
    </button>
  </div>
</template>

<script>
import IconBad from 'assets/icons/bad.svg'
import IconNeutral from 'assets/icons/neutral.svg'
import IconGood from 'assets/icons/good.svg'

export default {
  components: {
    IconBad,
    IconNeutral,
    IconGood
  },

  props: {
    label: {
      type: String,
      default: ''
    },
    mandatory: {
      type: Boolean,
      default: false
    },
    itemKey: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    hoverIcon: null,
    activeIcon: null
  }),

  methods: {
    setChoice(choice) {
      this.activeIcon = choice
      this.$log({
        choice: choice,
        key: this.itemKey
      })
      this.$emit('select', {
        choice: choice,
        key: this.itemKey
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './_assets/feedbackItem';
</style>
